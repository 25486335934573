<template>
  <div class="vg_wrapper">
    <el-card>
      <el-row>
        <el-col>
          <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border @row-dblclick="dbClickJp" v-loading="loading" class="mtrTab">
            <el-table-column label="材料采购号11" prop="modr_no"/>
            <el-table-column label="收货方简称" prop="supp_abbr">
              <template v-slot="scope">
                <span v-if="scope.row.supp_abbr">
                    {{ scope.row.supp_abbr}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="修改次数" prop="update_num"/>
            <el-table-column label="客户简称" prop="cust_abbr">
              <template v-slot="scope">
                <span v-if="scope.row.cust_abbr">{{ scope.row.cust_abbr}}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="签订日期" prop="scon_cust_nos">
              <template v-slot="scope">
                <span v-if="scope.row.dent_date">{{ scope.row.dent_date |  formatDate1}}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="订单数量" prop="dent_num"/>
            <el-table-column label="已发次数" prop="grant_num" show-overflow-tooltip/>
            <el-table-column label="已发数量" prop="dent_ynum" show-overflow-tooltip/>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import { dentAPI } from "@api/modules/dent";
import helper from "@assets/js/helper.js"
export default {
  name: "ShwePush9001",
  data(){
    return{
      tableData: [],
      loading: true,
    }
  },
  created() {
    this.initData()
  },
  mounted() {
  },
  filters: {
		formatDate1(row) {
			return helper.toTimeDay(row)
		},
	},
  methods: {
    initData() {
      this.loading = true;
      this.getPushList()
    },
    //获取信息
    getPushList(){
      get(dentAPI.getDentByScan)
          .then(res=>{
            if(res.data.code === 0){
              this.tableData = res.data.data.list
              setTimeout(() => {
                this.loading = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 双击
    dbClickJp(row){
      console.log(121323);
      post(dentAPI.editDentSacn,{dent_id: row.dent_id,scan: 0,})
      .then(res => {
        if (res.data.code === 0) {
          this.initData()
          this.jump('/dent_list',{perm_id:142,modr_no:row.modr_no})
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        }
      }).catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    buttonRefresh(){
      this.initData()
    }
  }
}
</script>

<style scoped lang="scss">
.vd_completed{
  color: $color-secondary-text;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.mtrTab {
  margin-top:10px ;
  cursor: pointer;
}
</style>